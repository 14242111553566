import Banner from "../Banner";
import NavBar from "../NavBar";
import Row from "../Row"
import movieRequests from "../request";
import classes from "./HomeScreen.module.css"

const HomeScreen = () => {
    return ( 
        <div className={classes.HomeScreen}>
            <NavBar />
            <Banner/> 
            <Row largeRow={true} URL={"https://api.themoviedb.org/3/movie/now_playing?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&page=1"} title="NOW PLAYING" /> 
            <Row URL={"https://api.themoviedb.org/3/movie/upcoming?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&page=6"} title="UPCOMING" />           
            <Row URL={"https://api.themoviedb.org/3/movie/top_rated?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&page=1"} title="TOP RATED" />           
            <Row URL={"https://api.themoviedb.org/3/discover/movie?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=true&page=1&with_genres=28&with_watch_monetization_types=flatrate"} title="ACTION" />           
            <Row URL={"https://api.themoviedb.org/3/discover/movie?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=true&page=1&with_genres=16&with_watch_monetization_types=flatrate"} title="ANIMATION" />           
            <Row URL={"https://api.themoviedb.org/3/discover/movie?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=true&page=1&with_genres=35&with_watch_monetization_types=flatrate"} title="COMEDY" />           
            <Row URL={"https://api.themoviedb.org/3/discover/movie?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=true&page=1&with_genres=10402&with_watch_monetization_types=flatrate"} title="MUSIC" />           
            <Row URL={"https://api.themoviedb.org/3/discover/movie?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=true&page=1&with_genres=9648&with_watch_monetization_types=flatrate"} title="MYSTERY" />           
            <Row URL={"https://api.themoviedb.org/3/discover/movie?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=true&page=15&with_genres=10749&with_watch_monetization_types=flatrate"} title="ROMANCE" />           
            <Row URL={"https://api.themoviedb.org/3/discover/movie?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&sort_by=popularity.desc&include_adult=false&include_video=true&page=1&with_genres=878&with_watch_monetization_types=flatrate"} title="SCIENCE FICTION" />                     
            {/* <Row largeRow={true} URL={movieRequests.fetchNowPlaying} title="NOW PLAYING" /> 
            <Row URL={movieRequests.fetchUpComing} title="UPCOMING" />           
            <Row URL={movieRequests.fetchTopRated} title="TOP RATED" />           
            <Row URL={movieRequests.fetchAction} title="ACTION" />           
            <Row URL={movieRequests.fetchAnimation} title="ANIMATION" />           
            <Row URL={movieRequests.fetchComedy} title="COMEDY" />           
            <Row URL={movieRequests.fetchMusic} title="MUSIC" />           
            <Row URL={movieRequests.fetchMystery} title="MYSTERY" />           
            <Row URL={movieRequests.fetchRomance} title="ROMANCE" />           
            <Row URL={movieRequests.fetchScienceFiction} title="SCIENCE FICTION" />                      */}
        </div>
     );
}
 
export default HomeScreen;