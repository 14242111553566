import classes from "./Row.module.css"
import { useEffect, useState } from "react";
import axios from "axios";

const Row = ({title, largeRow, URL}) => {

    const [filmList, setFilmList] = useState([])


    useEffect(() => {
        // const fetchMovies = async () => {
        //     const request = await axios.get(URL)
        //     const fetchedData = request?.data.results
        //     setFilmList(fetchedData)
        //     console.log(fetchedData)
        // }
        
        // return fetchMovies
        axios
            .get(URL)
            .then((response) => {
                setFilmList(response?.data.results)
                // console.log(response?.data.results) ;
            })
            .catch((err) => console.log(err))
        
    },[URL])

    
    return ( 
        <div className={classes.Row_container}>
            <div className={classes.Row_Title}>
                <h1>{title}</h1>
            </div>

            <div className={classes.Container}>
                {filmList?.map((movie) => {
                    const imgLink = `https://image.tmdb.org/t/p/original/${movie?.poster_path}`
                    return (movie?.poster_path && (
                        
                        <div className={largeRow ? classes.filmRows : classes.movieRows}>
                            <img src={imgLink} alt="movie_pic" />
                        </div>
                    )

                    )
                    
                    
                })}

            </div>
        </div>
     );
}
 
export default Row;