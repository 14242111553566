import classes from "./Banner.module.css"
import { useEffect, useState } from "react"
import axios from "axios"
import movieRequests from "./request"

const Banner = () => {

    const [films, setFilms] = useState([])

    const [filmPic, setFilmPic] = useState("")

    const [backgd, setBackgd] = useState("")

    useEffect(() => {
        // const fetchFilms = async () => {
        //     // const request = await axios.get(movieRequests?.fetchUpComing)
        //     const request = await axios.get("https://api.themoviedb.org/3/movie/upcoming?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&page=6")
        //     const selectedFilm = request?.data.results[
        //         Math.floor(Math.random() * (request.data.results.length - 1))
        //     ]
        //     setFilms(selectedFilm)
        //     console.log(selectedFilm)

        //     if (selectedFilm?.backdrop_path != null ) {
        //         setFilmPic(selectedFilm?.backdrop_path)
        //     }else{
        //         setFilmPic(selectedFilm?.poster_path)
        //     } 
        // }
        // return fetchFilms

        axios
            .get("https://api.themoviedb.org/3/movie/upcoming?api_key=2aa22b6ed5c335cc3e1c4433263150f1&language=en-US&page=6")
            .then((response) => {
                const selectedFilm = response?.data.results[
                    Math.floor(Math.random() * (response.data.results.length - 1))
                ]
                setFilms(response?.data.results[
                    Math.floor(Math.random() * (response.data.results.length - 1))
                ])
                // console.log(response?.data.results) ;
                if (selectedFilm?.backdrop_path != null ) {
                    setFilmPic(selectedFilm?.backdrop_path)
                }else{
                    setFilmPic(selectedFilm?.poster_path)
                } 

            })
            .catch((err) => console.log(err))

    }, [axios])
             

    const truncate = (description, n) => {
        return (
            description?.length > n? description.substr(0, n) + "..." : description
        )
    }

    useEffect(()=>{
        setBackgd(`url("https://image.tmdb.org/t/p/original/${filmPic && filmPic}")`)

    },[filmPic])


    // const bannerBackground = `url("https://image.tmdb.org/t/p/original/${filmPic && filmPic}")`

    return (

        <div className={classes.Background_Content} style={{
            backgroundImage: backgd,
            backgroundPosition: "center center",
            backgroundSize: "cover"
        }}>
            {console.log(backgd, "this is it")}
            <h1>{films?.title || films?.original_title}</h1>
            <div className={classes.Buttons}>
                <button className={classes.Button}>Play</button>
                <button className={classes.Button}>My List</button>
            </div>
            <div className={classes.Movie_Description}>
                <h4>{truncate(films?.overview, 150)} </h4>
            </div>

            <div className={classes.FadeButton}></div>

        </div>

    );
}
 
export default Banner;
