import { useState } from "react";
import classes from "./LoginScreen.module.css"
import SigninScreen from "./SigninScreen";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from "./../netflix__logo.png"

const LoginScreen = () => {
    const user = useSelector((state) => state.user.user)
    const navigate = useNavigate()
    const [signIn, setSignIn] = useState(false)

    const HomeClicked = () => {
        if (!user) {
           
            window.location.reload()
            
        }

        if (user) {
            navigate("/")
        }
    }

    return ( 
        <div className={classes.Container}>
            <div className={classes.LoginScreen_logo}>
                <img src={logo} alt="netflix logo" onClick={HomeClicked} />
                {!signIn && <button onClick={()=>setSignIn(true)}>Sign In</button>}
            </div>
            <div className={classes.Background_Content_Container}>
                {signIn ? (<SigninScreen />
                    ) : (
                        <div className={classes.Background_Content}>
                            <h1>Countless films, TV series, and many more.</h1>
                            <h3>Viewable from any location. Possible to cancel whenever desired.</h3>
                            <h4>Prepared for viewing? Input your email to establish a new membership or resume existing one.</h4>
                            <div className={classes.Input_Button}>
                                <input placeholder="Enter address" />
                                <button onClick={() => setSignIn(true)}>BEGIN NOW</button>
                            </div>
                        </div>
                    )
                }
                
            </div>
            
        </div>
     );
}
 
export default LoginScreen;